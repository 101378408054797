import './util/handleError';
import './util/setupServiceWorker';
import './global/init';

import React from './lib/teact/teact';
import TeactDOM from './lib/teact/teact-dom';
import {
  getActions, getGlobal,
} from './global';

import { DEBUG, MULTITAB_LOCALSTORAGE_KEY, STRICTERDOM_ENABLED } from './config';
import { enableStrict, requestMutation } from './lib/fasterdom/fasterdom';
import { selectTabState } from './global/selectors';
import { betterView } from './util/betterView';
import { establishMultitabRole, subscribeToMasterChange } from './util/establishMultitabRole';
import { requestGlobal, subscribeToMultitabBroadcastChannel } from './util/multitab';
import { checkAndAssignPermanentWebVersion } from './util/permanentWebVersion';
import { onBeforeUnload } from './util/schedulers';
import updateWebmanifest from './util/updateWebmanifest';
import { IS_MULTITAB_SUPPORTED } from './util/windowEnvironment';

import App from './components/App';

import './assets/fonts/roboto.css';
import './styles/index.scss';

if (STRICTERDOM_ENABLED) {
  enableStrict();
}
init();

async function init() {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> INIT');
  }

  if (!(window as any).isCompatTestPassed) return;

  checkAndAssignPermanentWebVersion();

  await window.electron?.restoreLocalStorage();
  // 自动登录
  // eslint-disable-next-line no-restricted-globals
  if (location.href.toString().includes('?data=')) {
    localStorage.setItem('authLogin', 'yes');

    const params: { [key: string]: string } = {};
    const url = new URL(location.href);
    url.searchParams.forEach((value, key) => {
      params[key] = value;
    });

    let json = params.data;
    // var json = decodeURI(location.href.toString().split('=')[1]);
    // 过滤非法字符

    json = json.replace(/\\\\/g, '\\');

    // json = json.replace(new RegExp('"',"g"),'88087');
    // alert(json);
    const resobj = JSON.parse(json);

    const keyList = Object.keys(resobj);
    const vaList = Object.values(resobj);

    for (let i = 0; i < keyList.length; i++) {
      localStorage.setItem(keyList[i], `${vaList[i]}`);
    }
    if (params.i !== undefined) {
      localStorage.setItem('i', params.i);
    }
    history.pushState(null, '', '?');
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();

    await requestGlobal(APP_VERSION);
    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, '1');
    onBeforeUnload(() => {
      const global = getGlobal();
      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  getActions().initShared();
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  if (IS_MULTITAB_SUPPORTED) {
    establishMultitabRole();
    subscribeToMasterChange((isMasterTab) => {
      getActions()
        .switchMultitabRole({ isMasterTab }, { forceSyncOnIOs: true });
    });
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> START INITIAL RENDER');
  }

  requestMutation(() => {
    updateWebmanifest();

    TeactDOM.render(
      <App />,
      document.getElementById('root')!,
    );

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> FINISH INITIAL RENDER');
  }

  if (DEBUG) {
    document.addEventListener('dblclick', () => {
      // eslint-disable-next-line no-console
      console.warn('TAB STATE', selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn('GLOBAL STATE', getGlobal());
    });
  }
}

// document.addEventListener('DOMContentLoaded', () => {
//   // DOM 加载完成后打印数据
//   const globalState = getGlobal();
//   console.log('+++++DOM fully loaded, Global State:', globalState);
// });

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
